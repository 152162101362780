<template>
	<div class="course">
		<el-tabs v-model="activeName" @tab-click="tabClick">
		    <el-tab-pane label="全部" name="all">
				<course-list :data="allList" @study-or-exam="studyOrExam" :total="total" :currpage="currpage"
				:pageSize="pageSize" @chang-page="changePage" ></course-list>
			</el-tab-pane>
			<el-tab-pane :label="item.dic_name" :name="item.dic_name" v-for="(item,index) in menuList" :key="index">
				<course-list :data="demandList" @study-or-exam="studyOrExam" :total="total" :currpage="currpage"
				:pageSize="pageSize" @chang-page="changePage" ></course-list>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
	import Bus from '/src/views/js/bus.js'; 
	import courseList from "/src/components/courseList";//列表页面
	export default {
		components: {
			courseList: courseList
		},
		data() {
			return {
				activeName:"all",//选项卡选中项
				allList:[],//全部课程列表
				demandList:[],//公需课、专业课课程列表
				total:0,
				pageSize:5,
				menuList:[],//选项卡
				currpage:1
			}
		},
		methods: {
			//获取课程
			myCourse(cou_typeid){
				this.$http.post(this.PublicJs.publicPath + "/api/student.Info/myCourse.html", {
					cou_typeid:cou_typeid,
					token:window.sessionStorage.getItem('token'),
					page:this.currpage,
					limit:this.pageSize,
				}, {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 1){
						this.total = response.data.data.total;
						if(this.activeName == "all"){
							this.allList = response.data.data.data;
						}else {
							this.demandList = response.data.data.data;
						}
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					if(response.status == 401){
						var that = this;
						this.$message({
							message: "您的账号在别处登录，请重新登录",
							type: 'warning',
							showClose: true
						});
						window.sessionStorage.removeItem('user_id')
						window.sessionStorage.removeItem('token')
						window.sessionStorage.removeItem('userinfo')
						setTimeout(function(){
							that.$router.go(0)
						},2000); 
						
					}
				});
			},
			//选项卡切换
			tabClick(){
				var cou_typeid = ""
				if(this.activeName != "all"){
					for(var i=0;i<this.menuList.length;i++){
						if(this.activeName == this.menuList[i].dic_name){
							cou_typeid = this.menuList[i].dic_id;
						}
					}
				}
				this.currpage = 1
				//获取课程
				this.myCourse(cou_typeid)
			},
			//获取分类和行业
			getTypeAndIndustry(){
				this.$http.post(this.PublicJs.publicPath + "/api/student.index/dictionary.html",{
					page:1,
					limit:999,
					dic_type:"couretype"
				}, {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 1){
						this.menuList = response.data.data.data;
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					console.log(response)
				});
			},
			//学习或考试
			studyOrExam(item){
				if(item.sig_passtext == "yes"){
					this.$message({
						message: "已完成考试",
						type: 'warning',
						showClose: true
					});
				}else{
					if(item.is_kaishi_code == 2){
						this.$router.push({
							path: "/exam"
						});
					}else{
						this.$router.push({
							path: "/videoPlay",
							query: {
								course_id:this.PublicJs.Encrypt(item.cou_id),
								source:this.PublicJs.Encrypt("course")
							}
						});
					}
				}
			},
			//分页
			changePage(val){
				var cou_typeid = ""
				if(this.activeName != "all"){
					for(var i=0;i<this.menuList.length;i++){
						if(this.activeName == this.menuList[i].dic_name){
							cou_typeid = this.menuList[i].dic_id;
						}
					}
				}
				this.currpage = val
				//获取课程
				this.myCourse(cou_typeid)
			},
		},
		created() {
			Bus.$emit('bus_menuChose', "course");
			//获取课程
			this.myCourse("")
			//获取分类
			this.getTypeAndIndustry()
		}
	}
</script>

<style scoped="scoped">
	.course,.el-tabs,.el-tab-pane {
		width: 100%;
		height: 100%;
		position: relative;
	}
	.el-tabs{
		padding-top: 60px;
	}
</style>
<style>
	.course .el-tabs__content{
		width: 100%;
		height: 100%;
	}
	.course .el-tabs__header{
		margin: 0;
		width: 100%;
		position: absolute;
		top: 0;
		left:0;
	}
	.course .el-tabs__item{
		height: 60px;
		line-height: 60px;
		font-size: 16px;
		color: #333;
		text-align: center;
		outline: none;
	}
	.course .el-tabs__nav-wrap::after{
		height: 1px;
		background-color: #E6E7EB;
	}
	.course .el-tabs__active-bar{
		background-color:#5E7BF2;
		height: 3px;
	}
	.course .el-tabs__item.is-active{
		font-weight: bold;
	}
	.course .el-tabs--top .el-tabs__item.is-top:nth-child(2){
		padding-left: 20px;
	}
</style>
