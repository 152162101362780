<template>
	<div class="courseList_box">
		<div class="courseList" v-if="data.length">
			<div class="courseItem" v-for="(item,index) in data" :key="index">
				<img :src="item.cou_thumb" class="courseImg" />
				<div class="courseInfo">
					<div class="courseName">{{item.cou_name}}</div>
					<div class="courseTY">
						<span class="courseType">{{item.course_type.dic_name}}</span>
						<span class="courseYear">【{{item.cou_year}}年】</span>
					</div>
					<div class="courseTS" v-if="item.sig_passtext == 'no'">
						<span class="courseTime" :class="{completed:item.is_kaishi == '完成'}">已学 {{PublicJs.formatSeconds(item.already_sum_time)}}</span>
						<span class="courseState" :title="'正在学习：'+item.kecheng.cow_name" v-if="item.is_kaishi == '未完成'">正在学习：{{item.kecheng.cow_name}}</span>
						<span class="courseState" v-else>{{item.is_kaishi}}</span>
					</div>
				</div>
				<div class="courseBtn" @click="studyOrExam(item)" :class="{pass:item.sig_passtext == 'yes'}">
					<span v-if="item.sig_passtext == 'yes'">已完成</span>
					<div v-else>
						<span v-if="item.is_kaishi_code==0">开始学习</span>
						<span v-else-if="item.is_kaishi_code==1">继续学习</span>
						<span v-else>前往考试</span>
					</div>
				</div>
			</div>
			<div class="orderPaginat" v-if="total > pageSize">
				<el-pagination
				  background
				  layout="prev, pager, next"
				  :total="total"
				  :page-size="pageSize"
				  :current-page="currpage"
				  @current-change="changPage">
				</el-pagination>
			</div>
		</div>
		<div class="noData" v-else>
			<img src="@/views/images/noData.png" />
			<div class="noDataTips">
				<span>空空如也，</span>
				<span class="cBlue"><router-link to='/CourseCenter'>去购买课程</router-link></span>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props:{
			data:{
				type: Array
			},
			total:{
				type: Number
			},
			pageSize:{
				type: Number
			},
			currpage:{
				type: Number
			},
		},
		methods:{
			//学习或者考试
			studyOrExam(val){
				this.$emit('study-or-exam', val);
			},
			//分页跳转
			changPage(val){
				this.$emit('chang-page', val);
			},
		}
	}
</script>

<style scoped="scoped">
	.noData{
		text-align: center;
		width: 100%;
		margin-top: 153px;
	}
	.noDataTips{
		font-size: 16px;
		color: #333;
		margin-top: 25px;
	}
	.cBlue a{
		color: #4d6ef2;
	}
	.cBlue a:hover{
		color: #1d48d2;
	}
	.courseList{
		padding: 0 37px;
	}
	.courseItem{
		height: 148px;
		width: 100%;
		border-bottom: 1px solid #EEEEEE;
		position: relative;
		padding-left: 175px;
		padding-right: 150px;
	}
	.courseImg{
		width: 150px;
		height: 90px;
		position: absolute;
		left: 0;
		top: 50%;
		margin-top: -45px;
		border-radius: 4px;
	}
	.courseName{
		font-size: 16px;
		color: #333;
		overflow: hidden;
		text-overflow:ellipsis;
		white-space: nowrap;
		padding-top: 28px;
		padding-bottom: 10px;
	}
	.courseType{
		display: inline-block;
		width: 46px;
		height: 24px;
		opacity: 1;
		background: #ffac3d;
		border-radius: 4px;
		font-size: 12px;
		color: #fff;
		text-align: center;
		line-height: 24px;
		margin-right: 13px;
	}
	.courseYear{
		font-size: 16px;
		color: #333;
	}
	.courseTime{
		font-size: 14px;
		color: #ff0000;
		margin-right: 38px;
	}
	.courseTime.completed{
		color: #2BA245;
	}
	.courseState{
		font-size: 14px;
		color: #333;
		overflow: hidden;
		text-overflow:ellipsis;
		white-space: nowrap;
		width: 350px;
		display: inline-block;
		vertical-align: middle;
	}
	.courseTY{
		margin-bottom: 13px;
		margin-top: 4px;
	}
	.courseBtn{
		width: 120px;
		height: 44px;
		opacity: 1;
		background: linear-gradient(0deg,rgba(77,110,242,0.90) 0%, rgba(77,110,242,0.70) 100%);
		border-radius: 22px;
		font-size: 14px;
		color: #FFF;
		text-align: center;
		line-height: 44px;
		position: absolute;
		right: 0;
		top: 50%;
		margin-top: -22px;
		cursor: pointer;
	}
	.courseBtn:hover{
		background: linear-gradient(0deg, rgba(77,110,242,0.70) 100%,rgba(77,110,242,0.90) 0%);
	}
	.courseBtn.pass{
		background: linear-gradient(0deg,rgba(14,173,82,0.90) 0%, rgba(132,241,165,0.70) 100%);
	}
	.courseBtn.pass:hover{
		background: linear-gradient(0deg,rgba(132,241,165,0.70) 0%, rgba(14,173,82,0.90) 100%);
	}
	.orderPaginat{
		text-align: center;
		padding: 20px 0;
	}
</style>
